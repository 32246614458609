import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo, useEffect } from 'react'
import Layout from '../../components/layout'
import { flatten } from '../../utils/data'
import ImageHero from '../../components/image-hero'
import tw from 'twin.macro'
import Heading from '../../components/heading'
import { global } from '../../styles/global'
import ContactCard from '../../components/contact-card'
import Gradient from '../../components/gradient'
import Image from '../../components/image'
import Values from '../../components/values'
import ClaimDropdown from '../../components/claim-dropdown'

const ClaimPage = ({ data: { claim }, location }) => {
  const page = useMemo(
    () => flatten(claim || {}, ['hero', 'stepsHeader', 'claimHeader', 'contactHeader']),
    [claim]
  )

  useEffect(() => {
    const hashtag = window.location.hash
    if (hashtag) {
      const currentURL = window.location.href
      const newURL = currentURL.split('#')[0]
      window.history.replaceState('', 'lunch', newURL)
    }
  })

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} headerTransition={true}>
      {/* <section id="about" css={tw`pt-20 relative xxl:pb-8 lg:pt-0 `}>
        <ImageHero
          image={get(page, 'hero.image')}
          imageMobile={get(page, 'hero.imageMobile')}
          title={get(page, 'hero.titleNode')}
          description={get(page, 'hero.descriptionNode')}
          subtitle={get(page, 'hero.subtitleNode')}
          hideCaret
          withGradient
        />
      </section> */}
      <section id="claim" css={tw`relative bg-white`}>
        <div css={tw`absolute lg:(left-72) xxl:left-108`}>
          <Gradient
            style={tw`z-10 absolute top-0 h-8 lg:(relative mt-0 h-14 w-full)`}
            firstColumnStyle={tw`self-end from-secondary-gradient to-secondary-500 h-8 w-28 sm:w-37 md:w-60 lg:(h-14 w-109)`}
            lastColumnStyle={tw` self-end bg-primary-500 h-8 w-8 lg:(h-14 w-14)`}
          />
        </div>
        <ClaimDropdown
          header={get(page, 'claimHeader')}
          image={get(page, 'claimImage')}
          mobileImage={get(page, 'claimMobileImage')}
          selectImage={get(page, 'claimSelectImage')}
          form={get(page, 'claimForms')}
          productPlaceholder={get(page, 'claimPlaceholder')}
          typePlaceholder={get(page, 'typePlaceholder')}
        />
        <Gradient
          style={tw`absolute right-0 bottom-0 h-8 w-fit-content lg:(bottom-6)`}
          firstColumnStyle={tw`h-8 w-8 bg-accent-500 lg:(h-56 w-14) `}
          lastColumnStyle={tw`h-8 from-primary-500 to-primary-gradient w-44 md:w-100 lg:(h-56 w-84)`}
        />
      </section>
      <section id="contactus" css={tw`relative pt-24 pb-22 bg-primary-50 lg:py-40`}>
        <div css={[global`layout.container`, global`layout.grid`]}>
          <Heading
            content={get(page, 'contactHeader.titleNode')}
            headingType="h2"
            style={tw`col-span-4  md:col-span-8 text-primary-500`}
          />
          <div
            css={tw`whitespace-pre flex flex-col col-span-4 space-y-8 mt-12 md:col-span-8 
            lg:(col-span-10) xl:(flex-row col-span-14 space-y-0 justify-between mt-24) xxl:(col-span-16)`}
          >
            {get(page, 'contactList').map((contact, i) => (
              <ContactCard
                key={i}
                label={contact.label}
                contactType={contact.contactType}
                image={contact.contactIcon}
                value={contact.valueText}
                subtext={contact.subTextNode}
                style={tw`flex-1`}
              />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

ClaimPage.propTypes = {
  data: PropTypes.shape({
    claim: PropTypes.object.isRequired,
  }),
}

export default ClaimPage

export const query = graphql`
  query ClaimQuery($locale: String!) {
    claim: datoCmsHkGiClaim(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        image {
          format
          gatsbyImageData(layout: FULL_WIDTH)
          url
        }
        imageMobile {
          format
          gatsbyImageData(layout: FULL_WIDTH)
          url
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      stepsHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      step {
        image {
          format
          url
        }
        title
        subtitle
      }

      claimHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }

      claimImage {
        url
        format
        gatsbyImageData(layout: FULL_WIDTH)
      }
      claimMobileImage {
        url
        format
        gatsbyImageData(layout: FULL_WIDTH)
      }
      claimSelectImage {
        url
        format
        gatsbyImageData(layout: FULL_WIDTH)
      }
      claimPlaceholder
      typePlaceholder
      claimForms {
        claimTitle
        claimType
        claimDescription
        form {
          claimFormTitle
          claimFormSubtitle
          claimFormDownloadIcon {
            url
            format
            gatsbyImageData(layout: FULL_WIDTH)
          }
          document {
            url
            filename
          }
          alternativeLink {
            label
            link
            disabled
          }
        }
        claimInstruction {
          title
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
        }
        contactInstruction {
          label
          contactType
          contactIcon {
            format
            url
          }
          value
          valueText
          subtextNode {
            childMarkdownRemark {
              html
            }
          }
        }
        documentInstruction {
          title
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
        }
        alternativeClaimTitle
        submitOnlineSubtitle
        callToActionOnlineClaims {
          callToAction {
            label
            link
            disabled
          }
        }
        alternativeClaim {
          claimFormTitle
          claimTitleLink {
            value
          }
          claimFormSubtitle
          claimFormDownloadIcon {
            url
            format
            gatsbyImageData(layout: FULL_WIDTH)
          }
          alternativeLink {
            link
          }
        }
        claimDescription
        submitOfflineSubtitle
      }
      contactHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactList {
        label
        contactType
        contactIcon {
          format
          url
        }
        value
        valueText
        subtextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactMap {
        url
        format
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
